import React, { useContext } from "react";
import { StyledGridRow } from "../../commons/Grid";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  StyledBusinessImpact,
  StyledBusinessImpactTop,
  StyledBusinessImpactBottom,
  StyledBusinessImpactTitle,
  StyledBusinessImpactSubtitle,
  StyledBusinessImpactItems,
  StyledBusinessImpactItemsGroup,
  StyledBusinessImpactItem,
  StyledBusinessImpactItemSmaller,
} from "./style";
import { DataContext } from "../../contexts/DataContext";

const BusinessImpact = ({
  title, subtitle, text1, text2, text3, text4, text4smaller, image,
}) => {
  const { pageImages } = useContext(DataContext);  
  const imageData = pageImages.find(e => e.path === image);

  return (
    <StyledGridRow>
      <StyledBusinessImpact>
        <GatsbyImage 
          style={{ width: "100%", minHeight: "400px", maxHeight: "520px" }} 
          imgStyle={{ borderRadius: "10px", filter: "brightness(0.5)" }} 
          image={getImage(imageData)} 
          alt={imageData.alt} />

        <StyledBusinessImpactTop>
          <StyledBusinessImpactTitle dangerouslySetInnerHTML={{ __html: title }}/>
          <StyledBusinessImpactSubtitle dangerouslySetInnerHTML={{ __html: subtitle }}/>
        </StyledBusinessImpactTop>

        <StyledBusinessImpactBottom>
          <StyledBusinessImpactItems>
            <StyledBusinessImpactItemsGroup>
              <StyledBusinessImpactItem dangerouslySetInnerHTML={{ __html: text1 }}/>
              <StyledBusinessImpactItem dangerouslySetInnerHTML={{ __html: text2 }}/>
            </StyledBusinessImpactItemsGroup>
            <StyledBusinessImpactItemsGroup>
              <StyledBusinessImpactItem dangerouslySetInnerHTML={{ __html: text3 }}/>
              <StyledBusinessImpactItem>
                <span dangerouslySetInnerHTML={{ __html: text4 }}/>
                <StyledBusinessImpactItemSmaller dangerouslySetInnerHTML={{ __html: text4smaller }}/>
              </StyledBusinessImpactItem>
            </StyledBusinessImpactItemsGroup>
          </StyledBusinessImpactItems>
        </StyledBusinessImpactBottom>
      </StyledBusinessImpact>
    </StyledGridRow>
  );
};
export default BusinessImpact;
